<template>
    <section class="dark:text-white">
        <auth-error v-if="error" :error="error" />
        <ButtonPrimaryLg v-if="!isLoading" class="mt-3" @click="handleReturnToSignInClick">
            {{ $l({ pl: "Powrót na stronę logowania", en: "Return to sign in page" }) }}
        </ButtonPrimaryLg>
    </section>
</template>

<script>
import { ButtonPrimaryLg } from "@easy/ui/buttons";
import AuthError from "../components/auth-error.vue";
import { loginService } from "../service/login.service";

// import("@easy/ui/style.css");

export default {
    name: "AuthCallback",
    components: { ButtonPrimaryLg, AuthError },
    data() {
        return {
            error: undefined,
            isLoading: false,
        };
    },
    computed: {
        code() {
            return this.$route.query.code || "";
        },
        clientId() {
            return this.$route.query.clientId || "";
        },
        logout() {
            const { logout = "" } = this.$route.query;
            return logout === "1" || logout === "true";
        },
        chained() {
            const { chained = "" } = this.$route.query;
            return chained === "1" || chained === "true";
        },
        redirectUrl() {
            const { redirect = "" } = this.$route.query;
            // @fix: decodeURIComponent is crucial to get proper http(s) redirection (absolute) and also relative
            const decodedRedirect = decodeURIComponent(redirect);
            if (decodedRedirect.startsWith("http")) {
                return decodedRedirect;
            }
            const baseUrl = window?.location?.origin;
            return `${baseUrl}/${decodedRedirect || "love"}`;
        },
    },
    async mounted() {
        const { code, clientId, logout } = this;
        this.isLoading = true;
        if (logout) {
            return this.logOut();
        }
        try {
            await loginService.logInWithCodeAndClientId(code, clientId, this.redirectUrl);
        } catch (e) {
            this.error = e;
            this.isLoading = false;
        }
    },
    methods: {
        handleReturnToSignInClick() {
            window.location.href = loginService.generateLogInLink(this.lang.value);
        },
        async logOut() {
            this.isLoading = true;
            if (this.chained) {
                await loginService.logOut(this.lang.value, true);
            } else {
                await loginService.logOutLocally();
                window.location = this.redirectUrl;
            }
        },
    },
};
</script>
