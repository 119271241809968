export const COOKIE_ROUTE_NAMES = {
    cookieProject: "cookie-project",
};

const CookieApp = () => import(/* webpackChunkName: "cookie_app" */ "./CookieApp.vue");
const CookieProjects = () =>
    import(/* webpackChunkName: "cookie_app" */ "./projects/CookieProjects.vue");
const CookieSettings = () =>
    import(/* webpackChunkName: "cookie_app" */ "./settings/CookieSettings.vue");
const CookiePanel = () => import(/* webpackChunkName: "cookie_app" */ "./panel/CookiePanel.vue");
const CookieRevoke = () => import(/* webpackChunkName: "cookie_app" */ "./revoke/CookieRevoke.vue");

export const cookieRoutes = {
    path: "/cookie-old",
    component: CookieApp,
    children: [
        { path: "", component: CookieProjects },
        { path: "projects", component: CookieProjects },
        { path: "settings", component: CookieSettings },
        {
            path: "projects/:project_uuid",
            name: COOKIE_ROUTE_NAMES.cookieProject,
            component: CookiePanel,
        },
        {
            path: "revoke/:project_uuid",
            name: "cookieRevoke",
            component: CookieRevoke,
        },
    ],
};
