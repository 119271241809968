import { onAppEnterHook } from "@/router/onAppEnter.hook";
import PreviewNotFound from "./PreviewNotFound.vue";

const PreviewMain = () => import("./PreviewMain.vue");
const PricingPreview = () => import(/* webpackChunkName: "pricing_app" */ "./PricingPreview.vue");
const LovePreview = () => import(/* webpackChunkName: "love_app" */ "./LovePreview.vue");
const LegalPreview = () => import(/* webpackChunkName: "legal_app" */ "./LegalPreview.vue");

export const PREVIEW_ROUTE_NAMES = {
    footersView: "footers-view",
    termsView: "terms-view",
    policiesView: "policies-view",
    aboutView: "about-view",
};

export const previewRoutes = {
    path: "/preview-old",
    component: PreviewMain,
    children: [
        { path: "not-found", component: PreviewNotFound },
        {
            path: "pricings/:pricing_uuid",
            component: PricingPreview,
            beforeEnter() {
                onAppEnterHook("pricing");
            },
        },
        {
            path: "walls/:wall_uuid",
            component: LovePreview,
            beforeEnter() {
                onAppEnterHook("love");
            },
        },
        {
            path: "footers/:footer_uuid",
            name: PREVIEW_ROUTE_NAMES.footersView,
            component: LegalPreview,
            beforeEnter() {
                onAppEnterHook("legal");
            },
        },
        {
            path: "terms/:legal_uuid",
            name: PREVIEW_ROUTE_NAMES.termsView,
            component: LegalPreview,
            beforeEnter() {
                onAppEnterHook("legal");
            },
        },
        {
            path: "policies/:legal_uuid",
            name: PREVIEW_ROUTE_NAMES.policiesView,
            component: LegalPreview,
            beforeEnter() {
                onAppEnterHook("legal");
            },
        },
        {
            path: "about/:legal_uuid",
            name: PREVIEW_ROUTE_NAMES.aboutView,
            component: LegalPreview,
            beforeEnter() {
                onAppEnterHook("legal");
            },
        },
    ],
};
