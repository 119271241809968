import { ajaxCall } from "@/shared/config/ajax-call";
import "./page.errors";
import { makeLogger } from "@/shared/consola";
import {
    handleErrorNotAnOwner,
    handleErrorByMessage,
    handleErrorUuidTaken,
} from "@/shared/services/with-store-error-handlers";

// eslint-disable-next-line no-unused-vars
const logger = makeLogger("pages.service");

const PAGE_ENDPOINT = `/pages`;

export const pagesService = {
    getAllForUserId({ userId }) {
        logger.trace("getAllForUserId", "page", userId);
        return ajaxCall.get(PAGE_ENDPOINT, { params: { userId } }).catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        logger.trace("getOneByUuid", "page", uuid);
        return ajaxCall.get(`${PAGE_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneByUuidForRender(uuid = "") {
        logger.trace("getOneByUuifForRender", "page", uuid);
        return ajaxCall.get(`${PAGE_ENDPOINT}/${uuid}/render`).catch(handleErrorByMessage());
    },
    create(name) {
        return ajaxCall.post(PAGE_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    removeProject(uuid) {
        logger.trace("removeProject", "page", uuid);
        return ajaxCall.delete(`${PAGE_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ uuid, name }) {
        logger.trace("clone", "page", uuid, "name", name);
        return ajaxCall
            .post(`${PAGE_ENDPOINT}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    rename({ uuid, newName }) {
        return ajaxCall
            .patch(`${PAGE_ENDPOINT}/${uuid}/name`, { newName })
            .catch(handleErrorByMessage());
    },
    updateByUuid(uuid, pageData) {
        logger.trace("updateByUuid", "page", uuid, "pageData", pageData);
        return ajaxCall.patch(`${PAGE_ENDPOINT}/${uuid}`, pageData).catch(handleErrorByMessage());
    },
    publish({ uuid, publish }) {
        return ajaxCall
            .patch(`${PAGE_ENDPOINT}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
    changeUuid({ uuid, newUuid }) {
        logger.trace("changeUuid", "page", uuid, "uuid", newUuid);
        return ajaxCall
            .patch(`${PAGE_ENDPOINT}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
};

export const PAGE_SECTION_TYPES = {
    wall: "wall",
    pricing: "pricing",
    faq: "faq",
    block: "block",
};

export const PAGE_BLOCK_TYPES = {
    hero: "hero",
    cta: "cta",
};
