import { ajaxCall } from "@/shared/config/ajax-call";
import "./faq.errors";
import {
    handleErrorNotAnOwner,
    handleErrorByMessage,
    handleErrorUuidTaken,
} from "@/shared/services/with-store-error-handlers";

import { makeLogger } from "@/shared/consola";
// eslint-disable-next-line no-unused-vars
const logger = makeLogger("faqs.service");

const FAQ_ENDPOINT = `/faqs`;

export const faqsService = {
    getAllForUser() {
        return ajaxCall.get(FAQ_ENDPOINT).catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall.get(`${FAQ_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneByUuidForRender(uuid = "") {
        return ajaxCall.get(`${FAQ_ENDPOINT}/${uuid}/render`).catch(handleErrorByMessage());
    },
    create(name) {
        return ajaxCall.post(FAQ_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    remove(uuid) {
        return ajaxCall.delete(`${FAQ_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ uuid, name }) {
        return ajaxCall
            .post(`${FAQ_ENDPOINT}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    rename({ uuid, newName }) {
        return ajaxCall
            .patch(`${FAQ_ENDPOINT}/${uuid}/name`, { newName })
            .catch(handleErrorByMessage());
    },
    updateByUuid(uuid, faqData) {
        return ajaxCall.patch(`${FAQ_ENDPOINT}/${uuid}`, faqData).catch(handleErrorByMessage());
    },
    publish({ uuid, publish }) {
        return ajaxCall
            .patch(`${FAQ_ENDPOINT}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
    changeUuid({ uuid, newUuid }) {
        return ajaxCall
            .patch(`${FAQ_ENDPOINT}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
};
