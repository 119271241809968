import { makeLogger } from "@/shared/consola";

const logger = makeLogger("player.router");

import(/* webpackChunkName: "player_app" */ "./player.route.navigate.js");

const PlayerApp = () => import(/* webpackChunkName: "player_app" */ "./PlayerApp.vue");
const PlayerProjectPage = () =>
    import(/* webpackChunkName: "player_app" */ "./pages/PlayerProjectPage.vue");
const PlayerListPage = () =>
    import(/* webpackChunkName: "player_app" */ "./pages/PlayerListPage.vue");

// Sidebar menus:
const SidebarFiles = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarFiles.vue");
const SidebarLayout = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarLayout.vue");
const SidebarPaywall = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarPaywall.vue");
const SidebarAnalitics = () =>
    import(
        /* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarAnalitics.vue"
    );
const SidebarStyles = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarStyles.vue");

export const PLAYER_ROUTE_NAMES = {
    projectPage: "project-player-page",
    projectPageSidebarFiles: "project-page-sidebar-player-files",
    projectPageSidebarLayout: "project-page-sidebar-player-layout",
    projectPageSidebarPaywall: "project-page-sidebar-player-paywall",
    projectPageSidebarAnalitics: "project-page-sidebar-player-analitics",
    projectPageSidebarStyles: "project-page-sidebar-player-styles",
    listPage: "list-player-page",
};

export const PLAYER_PREVIEW_KIND = {
    player: "player",
};

export const PLAYER_FIRST_SEGMENT = "player";

export const playerRoutes = [
    {
        path: `/${PLAYER_FIRST_SEGMENT}`,
        component: PlayerApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: PLAYER_ROUTE_NAMES.projectPage,
                component: PlayerProjectPage,
                children: [
                    {
                        path: "files",
                        name: PLAYER_ROUTE_NAMES.projectPageSidebarFiles,
                        component: SidebarFiles,
                    },
                    {
                        path: "layout",
                        name: PLAYER_ROUTE_NAMES.projectPageSidebarLayout,
                        component: SidebarLayout,
                    },
                    {
                        path: "paywall",
                        name: PLAYER_ROUTE_NAMES.projectPageSidebarPaywall,
                        component: SidebarPaywall,
                    },
                    {
                        path: "analitics",
                        name: PLAYER_ROUTE_NAMES.projectPageSidebarAnalitics,
                        component: SidebarAnalitics,
                    },
                    {
                        path: "styles",
                        name: PLAYER_ROUTE_NAMES.projectPageSidebarStyles,
                        component: SidebarStyles,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                PLAYER_ROUTE_NAMES.projectPageSidebarFiles
                            );
                            return {
                                name: PLAYER_ROUTE_NAMES.projectPageSidebarFiles,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: PLAYER_ROUTE_NAMES.listPage,
                component: PlayerListPage,
            },
        ],
    },
];
