import { unwrapPaginatedResponse } from "./service.hepers";
import { ajaxCall } from "@/shared/config/ajax-call";
import "./love.errors";
import {
    handleErrorNotAnOwner,
    handleErrorByMessage,
    handleErrorUuidTaken,
} from "@/shared/services/with-store-error-handlers";

const WALL_ENDPOINT = `/walls`;

export const wallsService = {
    getAllForUserId({ page, size } = {}) {
        return ajaxCall
            .get(WALL_ENDPOINT, { params: { page, size } })
            .then(unwrapPaginatedResponse)
            .catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall.get(`${WALL_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneByUuidForRender(uuid = "") {
        return ajaxCall.get(`${WALL_ENDPOINT}/${uuid}/render`).catch(handleErrorByMessage());
    },
    getOneByToken(id = "") {
        return ajaxCall.get(`${WALL_ENDPOINT}/token`, { params: { id } });
    },
    create(name) {
        return ajaxCall.post(WALL_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    remove(uuid) {
        return ajaxCall.delete(`${WALL_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ uuid, name, clone }) {
        return ajaxCall
            .post(`${WALL_ENDPOINT}/${uuid}/clone`, { name, clone })
            .catch(handleErrorByMessage());
    },
    rename({ uuid, newName }) {
        return ajaxCall
            .patch(`${WALL_ENDPOINT}/${uuid}/name`, { newName })
            .catch(handleErrorByMessage());
    },
    updateByUuid(uuid, wallData) {
        return ajaxCall.patch(`${WALL_ENDPOINT}/${uuid}`, wallData).catch(handleErrorByMessage());
    },
    //DEPRECATED, remove after easy 2.0
    requestForReview({ uuid, emails, content, subject }) {
        return ajaxCall
            .post(`${WALL_ENDPOINT}/${uuid}/request`, { emails, content, subject })
            .catch(handleErrorByMessage());
    },
    requestForReview2({ uuid, emails, content, subject }) {
        return ajaxCall
            .post(`${WALL_ENDPOINT}/${uuid}/request2`, { emails, content, subject })
            .catch(handleErrorByMessage());
    },
    publish({ uuid, publish }) {
        return ajaxCall
            .patch(`${WALL_ENDPOINT}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
    changeUuid({ uuid, newUuid }) {
        return ajaxCall
            .patch(`${WALL_ENDPOINT}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
};

export const WALL_LAYOUTS = {
    board: "board",
    bigslider: "bigslider",
    slider: "slider",
    multislider: "multislider",
    avatars: "avatars",
};
