import { ajaxCall } from "@/shared/config/ajax-call";
import "./cookie.errors";
import {
    handleErrorByMessage,
    handleErrorNotAnOwner,
} from "@/shared/services/with-store-error-handlers";
import { t } from "@/i18n";

const COOKIE_ENDPOINT = `/cookies`;

export const cookiesService = {
    getAllForUser() {
        return ajaxCall.get(COOKIE_ENDPOINT).catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall.get(`${COOKIE_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    create(name) {
        return ajaxCall.post(COOKIE_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    remove(uuid) {
        return ajaxCall.delete(`${COOKIE_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ uuid, name }) {
        return ajaxCall
            .post(`${COOKIE_ENDPOINT}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    rename({ uuid, newName }) {
        return ajaxCall
            .patch(`${COOKIE_ENDPOINT}/${uuid}/name`, { newName })
            .catch(handleErrorByMessage());
    },
    updateByUuid(uuid, cookieData) {
        return ajaxCall
            .patch(`${COOKIE_ENDPOINT}/${uuid}`, cookieData)
            .catch(handleErrorByMessage());
    },
    publish({ uuid, code, publish }) {
        return ajaxCall
            .post(`${COOKIE_ENDPOINT}/${uuid}/code`, { code, publish })
            .catch(handleErrorByMessage());
    },
    getConsentTypes() {
        return [
            "strict",
            "ad",
            "aduser",
            "adpersonalization",
            "analytics",
            "functionality",
            "personalization",
            "security",
        ];
    },
    getDefaultTypes() {
        return {
            strict: {
                name: t("cookie.groups.strict_name"),
                info: t("cookie.groups.strict_info"),
            },
            ad: {
                name: t("cookie.groups.ad_name"),
                info: t("cookie.groups.ad_info"),
            },
            aduser: {
                name: t("cookie.groups.aduser_name"),
                info: t("cookie.groups.aduser_info"),
            },
            adpersonalization: {
                name: t("cookie.groups.adpersonalization_name"),
                info: t("cookie.groups.adpersonalization_info"),
            },
            analytics: {
                name: t("cookie.groups.analytics_name"),
                info: t("cookie.groups.analytics_info"),
            },
            functionality: {
                name: t("cookie.groups.functionality_name"),
                info: t("cookie.groups.functionality_info"),
            },
            personalization: {
                name: t("cookie.groups.personalization_name"),
                info: t("cookie.groups.personalization_info"),
            },
            security: {
                name: t("cookie.groups.security_name"),
                info: t("cookie.groups.security_info"),
            },
        };
    },
};
