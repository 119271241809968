import { ajaxCall } from "@/shared/config/ajax-call";
import "./pricing.errors";
import { makeLogger } from "@/shared/consola";
import {
    handleErrorNotAnOwner,
    handleErrorByMessage,
    handleErrorUuidTaken,
    handleErrorNotPublished,
} from "@/shared/services/with-store-error-handlers";

// eslint-disable-next-line no-unused-vars
const logger = makeLogger("pricings.service");

const PRICING_ENDPOINT = `/pricings`;

export const pricingsService = {
    getAllForUser() {
        return ajaxCall.get(PRICING_ENDPOINT).catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall.get(`${PRICING_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneByUuidForRender(uuid = "") {
        return ajaxCall.get(`${PRICING_ENDPOINT}/${uuid}/render`).catch(handleErrorNotPublished());
    },
    getTenantPricingForRender(tenantSlug = "", isEasyCart = false) {
        return ajaxCall
            .get(`/ec2/${tenantSlug}?cart=${isEasyCart}`)
            .catch(handleErrorNotPublished());
    },
    getProductPricingForRender(tenantSlug = "", productSlug = "", isEasyCart = false) {
        return ajaxCall
            .get(`/ec2/${tenantSlug}/${productSlug}?cart=${isEasyCart}`)
            .catch(handleErrorNotPublished());
    },
    create(name) {
        return ajaxCall.post(PRICING_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    removeProject(uuid) {
        return ajaxCall.delete(`${PRICING_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ uuid, name }) {
        return ajaxCall
            .post(`${PRICING_ENDPOINT}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    rename({ uuid, newName }) {
        return ajaxCall
            .patch(`${PRICING_ENDPOINT}/${uuid}/name`, { newName })
            .catch(handleErrorByMessage());
    },
    updateByUuid(uuid, pricingData) {
        logger.trace("updateByUuid", uuid, pricingData);
        return ajaxCall
            .patch(`${PRICING_ENDPOINT}/${uuid}`, pricingData)
            .catch(handleErrorByMessage());
    },
    publish({ uuid, publish }) {
        return ajaxCall
            .patch(`${PRICING_ENDPOINT}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
    changeUuid({ uuid, newUuid }) {
        return ajaxCall
            .patch(`${PRICING_ENDPOINT}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
};

export const PRICING_HEADER_LAYOUTS = {
    standard: "standard",
    background: "background",
};

export const PRICING_LAYOUTS = {
    basic: "basic",
    grid: "grid",
    loop: "loop",
    list: "list",
    links: "links",
    shared: "shared",
    basicBottom: "basicBottom",
    gridBottom: "gridBottom",
};
