export const PLAYER_ROUTE_NAMES = {
    playerProject: "player-project",
};

const PlayerApp = () => import(/* webpackChunkName: "player_app" */ "./PlayerApp.vue");
const PlayerProjects = () =>
    import(/* webpackChunkName: "player_app" */ "./projects/PlayerProjects.vue");
const PlayerSettings = () =>
    import(/* webpackChunkName: "player_app" */ "./settings/PlayerSettings.vue");
const PlayerPanel = () => import(/* webpackChunkName: "player_app" */ "./panel/PlayerPanel.vue");

export const playerRoutes = {
    path: "/player-old",
    component: PlayerApp,
    children: [
        { path: "", component: PlayerProjects },
        { path: "projects", component: PlayerProjects },
        { path: "settings", component: PlayerSettings },
        {
            path: "projects/:project_uuid",
            name: PLAYER_ROUTE_NAMES.playerProject,
            component: PlayerPanel,
        },
    ],
};
