import { makeLogger } from "@/shared/consola";

const logger = makeLogger("page.router");

import(/* webpackChunkName: "page_app" */ "./page.route.navigate.js");

const PageApp = () => import(/* webpackChunkName: "page_app" */ "./PageApp.vue");
const PageProjectPage = () =>
    import(/* webpackChunkName: "page_app" */ "./pages/PageProjectPage.vue");
const PageListPage = () => import(/* webpackChunkName: "page_app" */ "./pages/PageListPage.vue");

// Sidebar menus:
const SidebarSections = () =>
    import(/* webpackChunkName: "page_app" */ "@/apps/page/project/sidebar/SidebarSections.vue");
const SidebarSettings = () =>
    import(/* webpackChunkName: "page_app" */ "@/apps/page/project/sidebar/SidebarSettings.vue");
const SidebarStyles = () =>
    import(/* webpackChunkName: "page_app" */ "@/apps/page/project/sidebar/SidebarStyles.vue");

export const PAGE_ROUTE_NAMES = {
    projectPage: "project-page-page",
    projectPageSidebarSections: "project-page-sidebar-page-files",
    projectPageSidebarSettings: "project-page-sidebar-page-layout",
    projectPageSidebarStyles: "project-page-sidebar-page-styles",
    listPage: "list-page-page",
};

export const PAGE_PREVIEW_KIND = {
    page: "page",
};

export const PAGE_FIRST_SEGMENT = "page";

export const pageRoutes = [
    {
        path: `/${PAGE_FIRST_SEGMENT}`,
        component: PageApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: PAGE_ROUTE_NAMES.projectPage,
                component: PageProjectPage,
                children: [
                    {
                        path: "sections",
                        name: PAGE_ROUTE_NAMES.projectPageSidebarSections,
                        component: SidebarSections,
                    },
                    {
                        path: "settings",
                        name: PAGE_ROUTE_NAMES.projectPageSidebarSettings,
                        component: SidebarSettings,
                    },
                    {
                        path: "styles",
                        name: PAGE_ROUTE_NAMES.projectPageSidebarStyles,
                        component: SidebarStyles,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                PAGE_ROUTE_NAMES.projectPageSidebarSections
                            );
                            return {
                                name: PAGE_ROUTE_NAMES.projectPageSidebarSections,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: PAGE_ROUTE_NAMES.listPage,
                component: PageListPage,
            },
        ],
    },
];
