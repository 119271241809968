import { registerServiceErrors } from "@/shared/errors/ajax-errors-mapper";

registerServiceErrors({
    ERR_FAQ_NOT_FOUND: {
        pl: "Nie znaleziono takiego projektu FAQ",
        en: "FAQ project not found",
    },
    ERR_FAQ_USER_NOT_FOUND: {
        pl: "Nie znaleziono użytkownika",
        en: "User not found",
    },
    ERR_FAQ_NAME_REQUIRED: {
        pl: "Nazwa jest wymagana",
        en: "Name is required",
    },
    ERR_FAQ_USER_REQUIRED: {
        pl: "Użytkownik jest wymagany",
        en: "User is required",
    },
});
