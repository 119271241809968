import { ajaxCall } from "@/shared/config/ajax-call";
import { handleErrorByMessage } from "@/shared/services/with-store-error-handlers";

const TIMER_ENDPOINT = `/timers`;

export const timerService = {
    getAllForUserId(userId) {
        return ajaxCall.get(TIMER_ENDPOINT, { params: { userId } }).catch(handleErrorByMessage());
    },
    getAllLanguagesLabels() {
        return ajaxCall.get(`${TIMER_ENDPOINT}/labels`).catch((e) => {
            console.error(e);
        });
    },
    create(name) {
        return ajaxCall.post(TIMER_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    remove(uuid) {
        return ajaxCall.delete(`${TIMER_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    publish({ uuid, publish }) {
        return ajaxCall
            .patch(`${TIMER_ENDPOINT}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
};
