export const LOVE_ROUTE_NAMES = {
    loveProject: "love-project",
    loveCreate: "love-create",
    loveConfirm: "love-confirm",
    loveAsk: "love-ask",
};

const LoveApp = () => import(/* webpackChunkName: "love_app" */ "./LoveApp.vue");
const LovePanel = () => import(/* webpackChunkName: "love_app" */ "./panel/LovePanel.vue");
const LoveProjects = () => import(/* webpackChunkName: "love_app" */ "./projects/LoveProjects.vue");
const LoveSettings = () => import(/* webpackChunkName: "love_app" */ "./settings/LoveSettings.vue");
const TestimonialAsk = () =>
    import(/* webpackChunkName: "love_app" */ "./testimonial/TestimonialAsk.vue");
const TestimonialPanel = () =>
    import(/* webpackChunkName: "love_app" */ "./testimonial/TestimonialPanel.vue");

export const loveRoutes = {
    path: "/love-old",
    component: LoveApp,
    children: [
        { path: "", component: LoveProjects },
        { path: "settings", component: LoveSettings },
        { path: "projects", component: LoveProjects },
        {
            path: "projects/:project_uuid",
            name: LOVE_ROUTE_NAMES.loveProject,
            component: LovePanel,
        },
        {
            path: "create/:create_uuid",
            name: LOVE_ROUTE_NAMES.loveCreate,
            component: TestimonialPanel,
        },
        { path: "ask/:create_uuid", name: LOVE_ROUTE_NAMES.loveAsk, component: TestimonialAsk },
        {
            path: "confirm/:confirm_uuid",
            name: LOVE_ROUTE_NAMES.loveConfirm,
            component: TestimonialPanel,
        },
    ],
};
