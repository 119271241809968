import { registerServiceErrors } from "@/shared/errors/ajax-errors-mapper";

registerServiceErrors({
    ERR_COFFEE_NOT_FOUND: {
        pl: "Nie znaleziono takiego projektu kawki",
        en: "Coffee project not found",
    },
    ERR_COFFEE_USER_NOT_FOUND: {
        pl: "Nie znaleziono użytkownika",
        en: "User not found",
    },
    ERR_COFFEE_NAME_REQUIRED: {
        pl: "Nazwa jest wymagana",
        en: "Name is required",
    },
    ERR_COFFEE_USER_REQUIRED: {
        pl: "Użytkownik jest wymagany",
        en: "User is required",
    },
});
