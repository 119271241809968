import { registerServiceErrors } from "@/shared/errors/ajax-errors-mapper";

registerServiceErrors({
    ERR_PRICING_NOT_FOUND: {
        pl: "Nie znaleziono cennika",
        en: "Pricing project not found",
    },
    ERR_PRICING_ALREADY_SYNC_PRODUCT: {
        pl: "Możesz mieć tylko 1 cennik zsynchronizowany dla tego produktu",
        en: "You can have only 1 synchronized project for this product",
    },
    ERR_PRICING_ALREADY_SYNC_TENANT: {
        pl: "Możesz mieć tylko 1 cennik zsynchronizowany dla tego sprzedawcy",
        en: "You can have only 1 synchronized project for this tenant",
    },
    ERR_PRICING_USER_REQUIRED: {
        pl: "Użytkownik jest wymagany",
        en: "User is required",
    },
    ERR_PRICING_NAME_REQUIRED: {
        pl: "Nazwa jest wymagana",
        en: "Name is required",
    },
    ERR_PRICING_USER_NOT_FOUND: {
        pl: "Nie znaleziono użytkownika",
        en: "User not found",
    },
});
