import { registerServiceErrors } from "@/shared/errors/ajax-errors-mapper";

registerServiceErrors({
    ERR_LOVE_WALL_NOT_FOUND: {
        pl: "Nie znaleziono ściany",
        en: "Wall of love not found",
    },
    ERR_LOVE_USER_NOT_FOUND: {
        pl: "Nie znaleziono użytkownika",
        en: "User not found",
    },
    ERR_LOVE_USER_REQUIRED: {
        pl: "Użytkownik jest wymagany",
        en: "User is required",
    },
    ERR_LOVE_NAME_REQUIRED: {
        pl: "Nazwa jest wymagana",
        en: "Name is required",
    },
    ERR_LOVE_PROBLEM_WITH_EMAILS: {
        pl: "Wystąpił problem z wysłaniem emaili",
        en: "Problem with sending emails",
    },
    ERR_LOVE_TESTIMONIAL_NOT_FOUND: {
        pl: "Nie znaleziono opinii",
        en: "Testimonial not found",
    },
    ERR_LOVE_TESTIMONIAL_UPDATE_PROBLEM: {
        pl: "Nie udało się zaktualizować opinii",
        en: "Could not update testimonial",
    },
    ERR_LOVE_TESTIMONIAL_ALREADY_CONFIRMED: {
        pl: "Opinia jest już potwierdzona",
        en: "Testimonial is already confirmed",
    },
    ERR_LOVE_TESTIMONIAL_CONFIRMATION_PROBLEM: {
        pl: "Nie udało się potwierdzić opinii",
        en: "Could not confirm testimonial",
    },
    ERR_LOVE_WRONG_CONFRIMATION_EMAIL: {
        pl: "Nieprawidłowy email. Proszę podać email na który otrzymałeś prośbę o potwierdzenie opinii.",
        en: "Invalid email. Please provide email on which you received this request.",
    },
});
