import { ajaxCall } from "@/shared/config/ajax-call";
import "./coffee.errors";
import {
    handleErrorNotAnOwner,
    handleErrorByMessage,
} from "@/shared/services/with-store-error-handlers";

import { makeLogger } from "@/shared/consola";
// eslint-disable-next-line no-unused-vars
const logger = makeLogger("coffees.service");

const COFFEE_ENDPOINT = `/coffees`;

export const coffeesService = {
    getAllForUserId({ userId }) {
        return ajaxCall.get(COFFEE_ENDPOINT, { params: { userId } }).catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall.get(`${COFFEE_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    create(name) {
        return ajaxCall.post(COFFEE_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    removeProject(id) {
        return ajaxCall.delete(`${COFFEE_ENDPOINT}/${id}`).catch(handleErrorByMessage());
    },
    clone({ id, name }) {
        return ajaxCall
            .post(`${COFFEE_ENDPOINT}/${id}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    rename({ id, name }) {
        return coffeesService.updateById(id, { name }).catch(handleErrorByMessage());
    },
    updateById(id, coffeeData) {
        return ajaxCall.patch(`${COFFEE_ENDPOINT}/${id}`, coffeeData).catch(handleErrorByMessage());
    },
};
