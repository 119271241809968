import { makeLogger } from "@/shared/consola";

const logger = makeLogger("faq.router");

import(/* webpackChunkName: "faq_app" */ "./faq.route.navigate.js");

const FaqApp = () => import(/* webpackChunkName: "faq_app" */ "./FaqApp.vue");
const FaqProjectPage = () => import(/* webpackChunkName: "faq_app" */ "./pages/FaqProjectPage.vue");
const FaqListPage = () => import(/* webpackChunkName: "faq_app" */ "./pages/FaqListPage.vue");

// Sidebar menus:
const SidebarQuestions = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarQuestions.vue");
const SidebarLayout = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarLayout.vue");
const SidebarStyles = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarStyles.vue");
const SidebarImport = () =>
    import(/* webpackChunkName: "faq_app" */ "@/apps/faq/project/sidebar/SidebarImport.vue");

export const FAQ_ROUTE_NAMES = {
    projectPage: "project-faq-page",
    projectPageSidebarQuestions: "project-page-sidebar-faq-questions",
    projectPageSidebarLayout: "project-page-sidebar-faq-layout",
    projectPageSidebarStyles: "project-page-sidebar-faq-styles",
    projectPageSidebarImport: "project-page-sidebar-faq-import",
    listPage: "list-faq-page",
};

export const FAQ_PREVIEW_KIND = {
    faq: "faq",
};

export const FAQ_FIRST_SEGMENT = "faq";

export const faqRoutes = [
    {
        path: `/${FAQ_FIRST_SEGMENT}`,
        component: FaqApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: FAQ_ROUTE_NAMES.projectPage,
                component: FaqProjectPage,
                children: [
                    {
                        path: "questions",
                        name: FAQ_ROUTE_NAMES.projectPageSidebarQuestions,
                        component: SidebarQuestions,
                    },
                    {
                        path: "import",
                        name: FAQ_ROUTE_NAMES.projectPageSidebarImport,
                        component: SidebarImport,
                    },
                    {
                        path: "layout",
                        name: FAQ_ROUTE_NAMES.projectPageSidebarLayout,
                        component: SidebarLayout,
                    },
                    {
                        path: "styles",
                        name: FAQ_ROUTE_NAMES.projectPageSidebarStyles,
                        component: SidebarStyles,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                FAQ_ROUTE_NAMES.projectPageSidebarQuestions
                            );
                            return {
                                name: FAQ_ROUTE_NAMES.projectPageSidebarQuestions,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: FAQ_ROUTE_NAMES.listPage,
                component: FaqListPage,
            },
        ],
    },
];
