import { makeLogger } from "@/shared/consola";

const logger = makeLogger("cookie.router");

import(/* webpackChunkName: "cookie_app" */ "./cookie.route.navigate.js");

const CookieApp = () => import(/* webpackChunkName: "cookie_app" */ "./CookieApp.vue");
const CookieProjectPage = () =>
    import(/* webpackChunkName: "cookie_app" */ "./pages/CookieProjectPage.vue");
const CookieListPage = () =>
    import(/* webpackChunkName: "cookie_app" */ "./pages/CookieListPage.vue");

// Sidebar menus:
const SidebarContent = () =>
    import(/* webpackChunkName: "cookie_app" */ "@/apps/cookie/project/sidebar/SidebarContent.vue");
const SidebarLayout = () =>
    import(/* webpackChunkName: "cookie_app" */ "@/apps/cookie/project/sidebar/SidebarLayout.vue");
const SidebarStyles = () =>
    import(/* webpackChunkName: "cookie_app" */ "@/apps/cookie/project/sidebar/SidebarStyles.vue");
const SidebarData = () =>
    import(/* webpackChunkName: "cookie_app" */ "@/apps/cookie/project/sidebar/SidebarData.vue");
const SidebarSettings = () =>
    import(
        /* webpackChunkName: "cookie_app" */ "@/apps/cookie/project/sidebar/SidebarSettings.vue"
    );

export const COOKIE_ROUTE_NAMES = {
    projectPage: "project-cookie-page",
    projectPageSidebarContent: "project-page-sidebar-cookie-content",
    projectPageSidebarLayout: "project-page-sidebar-cookie-layout",
    projectPageSidebarStyles: "project-page-sidebar-cookie-styles",
    projectPageSidebarData: "project-page-sidebar-cookie-data",
    projectPageSidebarSettings: "project-page-sidebar-cookie-settings",
    listPage: "list-cookie-page",
};

export const COOKIE_PREVIEW_KIND = {
    cookie: "cookie",
    adjust: "adjust",
};

export const COOKIE_FIRST_SEGMENT = "cookie";

export const cookieRoutes = [
    {
        path: `/${COOKIE_FIRST_SEGMENT}`,
        component: CookieApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: COOKIE_ROUTE_NAMES.projectPage,
                component: CookieProjectPage,
                children: [
                    {
                        path: "content",
                        name: COOKIE_ROUTE_NAMES.projectPageSidebarContent,
                        component: SidebarContent,
                    },
                    {
                        path: "layout",
                        name: COOKIE_ROUTE_NAMES.projectPageSidebarLayout,
                        component: SidebarLayout,
                    },
                    {
                        path: "styles",
                        name: COOKIE_ROUTE_NAMES.projectPageSidebarStyles,
                        component: SidebarStyles,
                    },
                    {
                        path: "data",
                        name: COOKIE_ROUTE_NAMES.projectPageSidebarData,
                        component: SidebarData,
                    },
                    {
                        path: "settings",
                        name: COOKIE_ROUTE_NAMES.projectPageSidebarSettings,
                        component: SidebarSettings,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                COOKIE_ROUTE_NAMES.projectPageSidebarContent
                            );
                            return {
                                name: COOKIE_ROUTE_NAMES.projectPageSidebarContent,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: COOKIE_ROUTE_NAMES.listPage,
                component: CookieListPage,
            },
        ],
    },
];
