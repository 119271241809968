import { ajaxCall } from "@/shared/config/ajax-call";
import "./player.errors";
import {
    handleErrorNotAnOwner,
    handleErrorByMessage,
    handleErrorUuidTaken,
    handleErrorNotPublished,
} from "@/shared/services/with-store-error-handlers";

import { makeLogger } from "@/shared/consola";
// eslint-disable-next-line no-unused-vars
const logger = makeLogger("players.service");

const PLAYER_ENDPOINT = `/players`;

export const playersService = {
    getAllForUser({ limit = 100 }) {
        return ajaxCall.get(PLAYER_ENDPOINT, { params: { limit } }).catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall.get(`${PLAYER_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneByUuidForRender(uuid = "") {
        return ajaxCall.get(`${PLAYER_ENDPOINT}/${uuid}/render`).catch(handleErrorNotPublished());
    },
    create(name) {
        return ajaxCall.post(PLAYER_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    removeProject(uuid) {
        return ajaxCall.delete(`${PLAYER_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ uuid, name }) {
        return ajaxCall
            .post(`${PLAYER_ENDPOINT}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    rename({ uuid, newName }) {
        return ajaxCall
            .patch(`${PLAYER_ENDPOINT}/${uuid}/name`, { newName })
            .catch(handleErrorByMessage());
    },
    updateByUuid(uuid, playerData) {
        return ajaxCall
            .patch(`${PLAYER_ENDPOINT}/${uuid}`, playerData)
            .catch(handleErrorByMessage());
    },
    publish({ uuid, publish }) {
        return ajaxCall
            .patch(`${PLAYER_ENDPOINT}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
    changeUuid({ uuid, newUuid }) {
        return ajaxCall
            .patch(`${PLAYER_ENDPOINT}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
};

export const PLAYER_FILE_TYPES = {
    video: "video",
    audio: "audio",
    file: "file",
    pdf: "pdf",
    google: "google",
    embed: "embed",
    text: "text",
    header: "header",
    image: "image",
};

export const PLAYER_VIDEO_SOURCES = {
    youtube: "youtube",
    vimeo: "vimeo",
    wistia: "wistia",
    other: "other",
};

export const PLAYER_HEADER_TYPES = {
    button: "button",
    link: "link",
    text: "text",
};

export const PLAYER_HEADER_SIDES = {
    left: "left",
    right: "right",
};
