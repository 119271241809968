import { makeLogger } from "@/shared/consola";
import { i18n } from "@/i18n";

// eslint-disable-next-line no-unused-vars
const logger = makeLogger("preview-language.service");

const LANG_PL = "pl";
const LANG_EN = "en";

export const previewLanguageService = {
    fallbackLanguage: LANG_EN,
    availableLanguages: [LANG_PL, LANG_EN],
    availableLanguagesWithLabels: [
        { value: LANG_PL, label: "PL" },
        { value: LANG_EN, label: "EN" },
    ],
    setLanguage(lang) {
        i18n.global.locale.value = lang;
    },
};
