<template>
    <div class="fixed bottom-4 right-4">
        <span @mouseenter="showInfo = true" :class="[`text-${activeApp}-500`]">
            <QuestionMarkCircleIcon class="h-12 w-12" />
        </span>
        <transition
            enter-class="transform translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2 "
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0 "
            leave-active-class="transition ease-in duration-100"
            leave-from-class=" opacity-100"
            leave-to-class=" opacity-0"
            appear
        >
            <p
                @mouseleave="showInfo = false"
                v-if="showInfo"
                class="absolute bottom-0 right-0 bg-white p-4 w-80 text-center shadow-lg"
            >
                {{ $l({ en: "Problem?", pl: "Masz problem?" }) }}
                <br />
                {{ $l({ en: "Write to", pl: "Napisz na" }) }}
                <a
                    :class="[`text-${activeApp}-500`]"
                    href="mailto:hello@easycart.pl?subject=Kontakt%20ze%20strony"
                >
                    hello@easycart.pl
                </a>
                {{ $l({ en: "or", pl: "lub" }) }}
                <br />
                <a
                    href="https://join.slack.com/t/easytools-space/shared_invite/zt-1260xwmpx-fWSqgi5LZAb7vvYkrBCSKA"
                    target="_blank"
                    :class="[`text-${activeApp}-500`]"
                >
                    {{
                        $l({
                            en: "join hundreds of users",
                            pl: "dołącz do setek użytkowników",
                        })
                    }}
                </a>
                {{
                    $l({
                        en: "of easytools who receive help and exchange insights on Slack!",
                        pl: "easytools, którzy otrzymują pomoc i wymieniają spostrzeżenia na Slacku!",
                    })
                }}
            </p>
        </transition>
    </div>
</template>

<script>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { mapState } from "vuex";

export default {
    name: "cHelp",
    components: {
        QuestionMarkCircleIcon,
    },
    data() {
        return {
            showInfo: false,
        };
    },
    computed: {
        ...mapState(["activeApp"]),
    },
};
</script>
