import bannerLogo from "@/shared-old/assets/easybanner_logo.svg";
import coffeeLogo from "@/shared/assets/easycoffee-32.png";
import cookieLogo from "@/shared/assets/easycookie-32.png";
import faqLogo from "@/shared/assets/easyfaq-32.png";
import legalLogo from "@/shared/assets/easylegal-32.png";
import loveLogo from "@/shared/assets/easylove-32.png";
import playerLogo from "@/shared/assets/easyplayer-32.png";
import pricingLogo from "@/shared/assets/easypricing-32.png";
import timerLogo from "@/shared/assets/easytimer-32.png";
import pageLogo from "@/shared/assets/easypage-32.png";

const META = {
    banner: { logo: bannerLogo, title: "Easybanner" },
    coffee: { logo: coffeeLogo, title: "Easycoffee" },
    cookie: { logo: cookieLogo, title: "Easycookie" },
    faq: { logo: faqLogo, title: "Easyfaq" },
    legal: { logo: legalLogo, title: "Easylegal" },
    love: { logo: loveLogo, title: "Easylove" },
    player: { logo: playerLogo, title: "Easyplayer" },
    pricing: { logo: pricingLogo, title: "Easyoffer" },
    timer: { logo: timerLogo, title: "Easytimer" },
    page: { logo: pageLogo, title: "Easypage" },
};

export function onAppEnterHook(appName) {
    const favIcon = document.querySelector("link[rel='icon']");
    if (favIcon) {
        favIcon.href = META[appName].logo;
    }
    document.title = META[appName].title;
}
