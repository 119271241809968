export const COFFEE_ROUTE_NAMES = {
    coffeeProject: "coffee-project",
};

const CoffeeApp = () => import(/* webpackChunkName: "coffee_app" */ "./CoffeeApp.vue");
const CoffeeProjects = () =>
    import(/* webpackChunkName: "coffee_app" */ "./projects/CoffeeProjects.vue");
const CoffeeSettings = () =>
    import(/* webpackChunkName: "coffee_app" */ "./settings/CoffeeSettings.vue");
const CoffeePanel = () => import(/* webpackChunkName: "coffee_app" */ "./panel/CoffeePanel.vue");

export const coffeeRoutes = {
    path: "/coffee-old",
    component: CoffeeApp,
    children: [
        { path: "", component: CoffeeProjects },
        { path: "projects", component: CoffeeProjects },
        { path: "settings", component: CoffeeSettings },
        {
            path: "projects/:project_uuid",
            name: COFFEE_ROUTE_NAMES.coffeeProject,
            component: CoffeePanel,
        },
    ],
};
