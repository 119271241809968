import { ajaxCall } from "@/shared/config/ajax-call";
import {
    handleErrorByMessage,
    handleErrorNotAnOwner,
    handleErrorUuidTaken,
} from "@/shared/services/with-store-error-handlers";
import "./legal.errors";
import { makeLogger } from "@/shared/consola";
// eslint-disable-next-line no-unused-vars
const logger = makeLogger("footers.service");

const FOOTER_ENDPOINT = `/footers`;

export const footersService = {
    getAllForUser() {
        return ajaxCall.get(FOOTER_ENDPOINT).catch(handleErrorByMessage());
    },
    getOneByUuid(uuid = "") {
        return ajaxCall.get(`${FOOTER_ENDPOINT}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneByUuidForRender(uuid = "") {
        return ajaxCall.get(`${FOOTER_ENDPOINT}/${uuid}/render`).catch(handleErrorByMessage());
    },
    // FIXME: to discuss routing
    getOneByLegalUuid(uuid = "") {
        return ajaxCall.get(`${FOOTER_ENDPOINT}/legal/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneByLegalUuidForRender(uuid = "") {
        return ajaxCall
            .get(`${FOOTER_ENDPOINT}/legal/${uuid}/render`)
            .catch(handleErrorNotAnOwner());
    },
    create(name) {
        return ajaxCall.post(FOOTER_ENDPOINT, { name }).catch(handleErrorByMessage());
    },
    remove(uuid) {
        return ajaxCall.delete(`${FOOTER_ENDPOINT}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ uuid, name }) {
        return ajaxCall
            .post(`${FOOTER_ENDPOINT}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    rename({ uuid, newName }) {
        return ajaxCall
            .patch(`${FOOTER_ENDPOINT}/${uuid}/name`, { newName })
            .catch(handleErrorByMessage());
    },
    updateByUuid(uuid, footerData) {
        return ajaxCall
            .patch(`${FOOTER_ENDPOINT}/${uuid}`, footerData)
            .catch(handleErrorByMessage());
    },
    publish({ uuid, publish }) {
        return ajaxCall
            .patch(`${FOOTER_ENDPOINT}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
    changeUuid({ uuid, newUuid }) {
        return ajaxCall
            .patch(`${FOOTER_ENDPOINT}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
    getCompanyData(nip) {
        return ajaxCall
            .post(`${FOOTER_ENDPOINT}/company-details`, { nip })
            .catch(handleErrorByMessage());
    },
};
