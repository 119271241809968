<template>
    <main class="grid min-h-full place-items-center bg-gray-50 px-6 py-24 sm:py-32 lg:px-8">
        <div class="text-center">
            <p class="flex justify-center text-base font-semibold text-indigo-600">
                <ArrowPathIcon class="h-10 w-10" />
            </p>
            <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {{
                    $l({
                        en: "Preview problem",
                        pl: "Problem z podglądem",
                    })
                }}
            </h1>
            <p class="mt-6 text-base leading-7 text-gray-600">
                {{
                    $l({
                        en: "Please save your changes and refresh the page",
                        pl: "Proszę zapisz swoje zmiany i odśwież stronę",
                    })
                }}
            </p>
        </div>
    </main>
    <c-help />
</template>

<script>
import cHelp from "@/shared-old/components/cHelp.vue";
import { ArrowPathIcon } from "@heroicons/vue/24/solid";

export default {
    name: "PreviewNotFound",
    components: {
        cHelp,
        ArrowPathIcon,
    },
};
</script>
