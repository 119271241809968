import { onAppEnterHook } from "@/router/onAppEnter.hook";

const RenderMain = () => import(/* webpackChunkName: "render" */ "./RenderMain.vue");
const LoveWallPage = () => import(/* webpackChunkName: "render" */ "./LoveWallPage.vue");
const LoveFormPage = () => import(/* webpackChunkName: "render" */ "./LoveFormPage.vue");
const LegalPage = () => import(/* webpackChunkName: "render" */ "./LegalPage.vue");
const FaqPage = () => import(/* webpackChunkName: "render" */ "./FaqPage.vue");
const PricingPage = () => import(/* webpackChunkName: "render" */ "./PricingPage.vue");
const PlayerPage = () => import(/* webpackChunkName: "render" */ "./PlayerPage.vue");
const PagePage = () => import(/* webpackChunkName: "render" */ "./PagePage.vue");

const RenderNotFound = () => import(/* webpackChunkName: "render" */ "./RenderNotFound.vue");

const RenderNotPublished = () =>
    import(/* webpackChunkName: "render" */ "./RenderNotPublished.vue");

export const RENDER_ROUTE_NAMES = {
    notFound: "render-not-found",
    notPublished: "render-not-published",
    loveWall: "render-love-wall",
    loveForm: "render-love-form",
    loveReviewConfirm: "render-love-review-confirm",
    legalFooter: "render-legal-footer",
    legalTerms: "render-legal-terms",
    legalTermsEmbed: "render-legal-terms-embed",
    legalPrivacy: "render-legal-privacy",
    legalPrivacyEmbed: "render-legal-privacy-embed",
    legalAbout: "render-legal-about",
    faq: "render-faq",
    pricing: "render-pricing",
    pricingEcTenant: "render-pricing-ec-tenant",
    pricingEcProduct: "render-pricing-ec-product",
    player: "render-player",
    page: "render-page",
};

export const renderRoutes = [
    {
        path: "",
        component: RenderMain,
        children: [
            {
                path: `render/not-found`,
                name: RENDER_ROUTE_NAMES.notFound,
                component: RenderNotFound,
            },
            {
                path: `render/not-published`,
                name: RENDER_ROUTE_NAMES.notPublished,
                component: RenderNotPublished,
            },
            {
                path: "walls/:projectUuid",
                component: LoveWallPage,
                name: RENDER_ROUTE_NAMES.loveWall,
                beforeEnter() {
                    onAppEnterHook("love");
                },
            },
            {
                path: "review-ask/:projectUuid",
                component: LoveFormPage,
                name: RENDER_ROUTE_NAMES.loveForm,
                beforeEnter() {
                    onAppEnterHook("love");
                },
            },
            {
                path: "review-confirm/:projectUuid/:reviewUuid",
                component: LoveFormPage,
                name: RENDER_ROUTE_NAMES.loveReviewConfirm,
                beforeEnter() {
                    onAppEnterHook("love");
                },
            },
            {
                path: `footers/:projectUuid`,
                component: LegalPage,
                name: RENDER_ROUTE_NAMES.legalFooter,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `terms/:legalUuid`,
                component: LegalPage,
                name: RENDER_ROUTE_NAMES.legalTerms,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `terms-embed/:legalUuid`,
                component: LegalPage,
                name: RENDER_ROUTE_NAMES.legalTermsEmbed,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `policies/:legalUuid`,
                component: LegalPage,
                name: RENDER_ROUTE_NAMES.legalPrivacy,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `policies-embed/:legalUuid`,
                component: LegalPage,
                name: RENDER_ROUTE_NAMES.legalPrivacyEmbed,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `about/:legalUuid`,
                component: LegalPage,
                name: RENDER_ROUTE_NAMES.legalAbout,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: "faqs/:projectUuid",
                component: FaqPage,
                name: RENDER_ROUTE_NAMES.faq,
                beforeEnter() {
                    onAppEnterHook("faq");
                },
            },
            {
                path: "pricings/:projectUuid",
                component: PricingPage,
                name: RENDER_ROUTE_NAMES.pricing,
                beforeEnter() {
                    onAppEnterHook("pricing");
                },
            },
            {
                path: "ec/:tenantSlug",
                component: PricingPage,
                name: RENDER_ROUTE_NAMES.pricingEcTenant,
                beforeEnter() {
                    onAppEnterHook("pricing");
                },
            },
            {
                path: "ec/:tenantSlug/:productSlug",
                component: PricingPage,
                name: RENDER_ROUTE_NAMES.pricingEcProduct,
                beforeEnter() {
                    onAppEnterHook("pricing");
                },
            },
            {
                path: "players/:projectUuid",
                component: PlayerPage,
                name: RENDER_ROUTE_NAMES.player,
                beforeEnter() {
                    onAppEnterHook("player");
                },
            },
            {
                path: "pages/:projectUuid",
                component: PagePage,
                name: RENDER_ROUTE_NAMES.page,
                beforeEnter() {
                    onAppEnterHook("page");
                },
            },
        ],
    },
];
