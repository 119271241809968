import { LOVE_FIRST_SEGMENT } from "@/apps/love/love.routes";
import { wallsService } from "@/apps/love/services/walls.service";
import router from "@/router";
import { LEGAL_FIRST_SEGMENT } from "@/apps/legal/legal.routes";
import { footersService } from "@/apps/legal/services/footers.service";
import store from "@/store";
import { COFFEE_FIRST_SEGMENT } from "@/apps/coffee/coffee.routes";
import { coffeesService } from "@/apps/coffee/services/coffees.service";
import { COOKIE_FIRST_SEGMENT } from "@/apps/cookie/cookie.routes";
import { cookiesService } from "@/apps/cookie/services/cookies.service";
import { FAQ_FIRST_SEGMENT } from "@/apps/faq/faq.routes";
import { faqsService } from "@/apps/faq/services/faqs.service";

import { t } from "@/i18n";
import { PLAYER_FIRST_SEGMENT } from "@/apps/player/player.routes";
import { playersService } from "@/apps/player/services/players.service";
import { PAGE_FIRST_SEGMENT } from "@/apps/page/page.routes";
import { pagesService } from "@/apps/page/services/page.service";
import { PRICING_FIRST_SEGMENT } from "@/apps/pricing/pricing.routes";
import { pricingsService } from "@/apps/pricing/services/pricing.service";
import { TIMER_FIRST_SEGMENT } from "@/apps/timer/timer.routes";
import { timerService } from "@/apps/timer/services/timer.service";
import { userService } from "@/auth/service/user.service";

const BaseLayoutWithAlerts = () => import("@/shared/views/vMainLayout.vue");
const DEFAULT_PROJECT_NAME = "My sample project";
const DEFAULT_PROJECT_HANDLER = (res, appPath) => router.push(`/${appPath}/projects/${res.uuid}`);

const serviceRepoActions = {
    [COFFEE_FIRST_SEGMENT]: {
        create: coffeesService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [COOKIE_FIRST_SEGMENT]: {
        create: cookiesService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [FAQ_FIRST_SEGMENT]: {
        create: faqsService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [LEGAL_FIRST_SEGMENT]: {
        create: footersService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [LOVE_FIRST_SEGMENT]: {
        create: wallsService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [PAGE_FIRST_SEGMENT]: {
        create: pagesService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [PLAYER_FIRST_SEGMENT]: {
        create: playersService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [PRICING_FIRST_SEGMENT]: {
        create: pricingsService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
    [TIMER_FIRST_SEGMENT]: {
        create: timerService.create,
        name: DEFAULT_PROJECT_NAME,
        handler: DEFAULT_PROJECT_HANDLER,
    },
};

function chooseServiceAction(appPathSegment) {
    const action = serviceRepoActions[appPathSegment];
    if (!action) {
        throw new Error("create action not found :(");
    }
    return action;
}

export function createProjectRoute() {
    return {
        path: "/:appName/project-create",
        async beforeEnter({ params = {}, query }) {
            const { appName } = params;
            const { name: nameFromQuery } = query;
            const { create, name, handler } = chooseServiceAction(appName);
            const computedName = nameFromQuery || name;
            // check if user is logged in:
            await store.dispatch("getUser");
            if (store.getters.logged) {
                setTimeout(async () => {
                    const decision = await store.dispatch("modals/showConfirmModal", {
                        title: t("shared.test_project_modal.title"),
                        description: t("shared.test_project_modal.desc"),
                        ctaLabel: t("shared.go_to_dashboard"),
                        cancelLabel: undefined,
                    });
                    if (decision) {
                        const { magicUrl } = await userService.forgeMagicLink(
                            store.getters.activeAppDashboardLink
                        );
                        window.location = magicUrl;
                    }
                }, 1000);
                return true;
            }
            const response = await create(computedName);
            await handler(response, appName);
        },
        component: BaseLayoutWithAlerts,
    };
}
