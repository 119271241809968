import { registerServiceErrors } from "@/shared/errors/ajax-errors-mapper";

registerServiceErrors({
    ERR_PLAYER_NOT_FOUND: {
        pl: "Nie znaleziono playera",
        en: "Player not found",
    },
    ERR_PLAYER_USER_NOT_FOUND: {
        pl: "Nie znaleziono użytkownika",
        en: "User not found",
    },
    ERR_PLAYER_EC_PRODUCT_ID_REQUIRED: {
        pl: "ID produktu w easycart jest wymagane",
        en: "Easycart product ID is required",
    },
    ERR_PLAYER_NAME_REQUIRED: {
        pl: "Nazwa jest wymagana",
        en: "Name is required",
    },
    ERR_PLAYER_HEADER_NOT_FOUND: {
        pl: "Nie znaleziono nagłówka",
        en: "Header not found",
    },
    ERR_PLAYER_HEADER_UPDATE_PROBLEM: {
        pl: "Nie udało się zaktualizować nagłówka",
        en: "Could not update header",
    },
    ERR_PLAYER_HEADER_DELETE_PROBLEM: {
        pl: "Nie udało się usunąć nagłówka",
        en: "Could not delete header",
    },
    ERR_PLAYER_HEADER_ORDER_PROBLEM: {
        pl: "Nie udało się zmienić kolejności nagłówków",
        en: "Could not change headers order",
    },
});
