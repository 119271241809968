import { createRouter, createWebHistory } from "vue-router";

import { authRoutes } from "@/auth/auth.routes";
import { bannerRoutes } from "@/apps-old/easybanner/banner.routes";
import { coffeeRoutes } from "@/apps-old/easycoffee/coffee.routes";
import { cookieRoutes } from "@/apps-old/easycookie/cookie.routes";
import { legalRoutes } from "@/apps-old/easylegal/legal.routes";
import { loveRoutes } from "@/apps-old/easylove/love.routes";
import { playerRoutes } from "@/apps-old/easyplayer/player.routes";
import { pricingRoutes } from "@/apps-old/easypricing/pricing.routes";
import { previewRoutes } from "@/views-old/preview/preview.routes";
import { pageRoutes } from "@/apps-old/easypage/page.routes";
import { appRoutes } from "@/router/appRoutes";
import { onNavigateAwayGuards } from "@/router/onNavigateAway.guard";

const RenderNotFound = () =>
    import(/* webpackChunkName: "render" */ "@/views/render/RenderNotFound.vue");

const { VUE_APP_DEV_START_TOOL, NODE_ENV } = process.env;

const routes = [
    // This element is a redirect to the default app when in development mode.
    NODE_ENV === "development" && VUE_APP_DEV_START_TOOL
        ? { path: "", redirect: `/${VUE_APP_DEV_START_TOOL}/projects-list` }
        : {}, // make VUE_APP_DEV_START_TOOL a default app for now.
    { ...authRoutes },
    // start: to be removed after 2.0
    { ...bannerRoutes },
    { ...coffeeRoutes },
    { ...cookieRoutes },
    { ...legalRoutes },
    { ...loveRoutes },
    { ...playerRoutes },
    { ...pricingRoutes },
    { ...previewRoutes },
    { ...pageRoutes },
    // end: to be removed after 2.0
    ...appRoutes,
    { path: "/:catchAll(.*)", component: RenderNotFound },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

router.beforeEach((to, from) => {
    return onNavigateAwayGuards(to, from);
});
