export const PRICING_ROUTE_NAMES = {
    pricingProject: "pricing-project",
};

const PricingApp = () => import(/* webpackChunkName: "pricing_app" */ "./PricingApp.vue");
const PricingProjects = () =>
    import(/* webpackChunkName: "pricing_app" */ "./projects/PricingProjects.vue");
const PricingSettings = () =>
    import(/* webpackChunkName: "pricing_app" */ "./settings/PricingSettings.vue");
const PricingPanel = () => import(/* webpackChunkName: "pricing_app" */ "./panel/PricingPanel.vue");

export const pricingRoutes = {
    path: "/pricing-old",
    component: PricingApp,
    children: [
        { path: "", component: PricingProjects },
        { path: "projects", component: PricingProjects },
        { path: "settings", component: PricingSettings },
        {
            path: "projects/:project_uuid",
            name: PRICING_ROUTE_NAMES.pricingProject,
            component: PricingPanel,
        },
    ],
};
