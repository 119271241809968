import lowerCase from "lodash/lowerCase";
// TODO: refactor cloneDeep to actually cloning by lodash:
// import lodashCloneDeep from "lodash/cloneDeep";
import slug from "slugify";
import markdownit from "markdown-it";

export function cloneDeep(obj) {
    // return lodashCloneDeep(obj);
    return JSON.parse(JSON.stringify(obj));
}

export function isObjectNotEmpty(obj) {
    return obj && Object.keys(obj).length;
}

export function slugify(str) {
    return slug(lowerCase(str), "-");
}

export function waitForMe(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function randomId() {
    return ((1 + Math.random()) * 0x100000) | 0;
}

export function isColorDark(color) {
    // Add missing characters if necessary
    if (color.length === 4) {
        color = color.replace(/#(.)(.)(.)/, "#$1$1$2$2$3$3");
    }

    // Convert color to its RGB components
    color = color.substring(1); // Remove the '#' at the beginning
    const r = parseInt(color.slice(0, 2), 16) / 255;
    const g = parseInt(color.slice(2, 4), 16) / 255;
    const b = parseInt(color.slice(4, 6), 16) / 255;

    // Calculate the relative luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance <= 0.5;
}

export function hexToRgba(hex, opacity = "1") {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
              result[3],
              16
          )},${opacity})`
        : null;
}

export function renderMarkdown(text) {
    const md = markdownit({ html: true });
    return md.render(text || "");
}

export function isOSSystem() {
    const userAgentData = navigator.userAgentData;
    if (userAgentData) {
        const platform = userAgentData.platform.toLowerCase();
        if (platform.includes("mac")) {
            return true;
        }
        if (platform.includes("win")) {
            return false;
        }
    } else {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes("mac")) {
            return true;
        }
        if (userAgent.includes("win")) {
            return false;
        }
    }
    return false;
}
