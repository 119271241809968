import { makeLogger } from "@/shared/consola";

const logger = makeLogger("pricing.router");

import(/* webpackChunkName: "pricing_app" */ "./pricing.route.navigate.js");

const PricingApp = () => import(/* webpackChunkName: "pricing_app" */ "./PricingApp.vue");
const PricingProjectPage = () =>
    import(/* webpackChunkName: "pricing_app" */ "./pages/PricingProjectPage.vue");
const PricingListPage = () =>
    import(/* webpackChunkName: "pricing_app" */ "./pages/PricingListPage.vue");

// Sidebar menus:
const SidebarVariants = () =>
    import(
        /* webpackChunkName: "pricing_app" */ "@/apps/pricing/project/sidebar/SidebarVariants.vue"
    );
const SidebarLayout = () =>
    import(
        /* webpackChunkName: "pricing_app" */ "@/apps/pricing/project/sidebar/SidebarLayout.vue"
    );
const SidebarStyles = () =>
    import(
        /* webpackChunkName: "pricing_app" */ "@/apps/pricing/project/sidebar/SidebarStyles.vue"
    );
const SidebarHeader = () =>
    import(
        /* webpackChunkName: "pricing_app" */ "@/apps/pricing/project/sidebar/SidebarHeader.vue"
    );
const SidebarProduct = () =>
    import(
        /* webpackChunkName: "pricing_app" */ "@/apps/pricing/project/sidebar/SidebarProduct.vue"
    );

export const PRICING_ROUTE_NAMES = {
    projectPage: "project-pricing-page",
    projectPageSidebarVariants: "project-page-sidebar-pricing-variants",
    projectPageSidebarLayout: "project-page-sidebar-pricing-layout",
    projectPageSidebarStyles: "project-page-sidebar-pricing-styles",
    projectPageSidebarHeader: "project-page-sidebar-pricing-header",
    projectPageSidebarProduct: "project-page-sidebar-pricing-product",
    listPage: "list-pricing-page",
};

export const PRICING_PREVIEW_KIND = {
    pricing: "pricing",
};

export const PRICING_FIRST_SEGMENT = "pricing";

export const pricingRoutes = [
    {
        path: `/${PRICING_FIRST_SEGMENT}`,
        component: PricingApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: PRICING_ROUTE_NAMES.projectPage,
                component: PricingProjectPage,
                children: [
                    {
                        path: "variants",
                        name: PRICING_ROUTE_NAMES.projectPageSidebarVariants,
                        component: SidebarVariants,
                        meta: { menuCategory: "variants" },
                    },
                    {
                        path: "header",
                        name: PRICING_ROUTE_NAMES.projectPageSidebarHeader,
                        component: SidebarHeader,
                    },
                    {
                        path: "layout",
                        name: PRICING_ROUTE_NAMES.projectPageSidebarLayout,
                        component: SidebarLayout,
                    },
                    {
                        path: "styles",
                        name: PRICING_ROUTE_NAMES.projectPageSidebarStyles,
                        component: SidebarStyles,
                    },
                    {
                        path: "product",
                        name: PRICING_ROUTE_NAMES.projectPageSidebarProduct,
                        component: SidebarProduct,
                        meta: { menuCategory: "variants" },
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                PRICING_ROUTE_NAMES.projectPageSidebarVariants
                            );
                            return {
                                name: PRICING_ROUTE_NAMES.projectPageSidebarVariants,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: PRICING_ROUTE_NAMES.listPage,
                component: PricingListPage,
            },
        ],
    },
];
