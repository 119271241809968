export const BANNER_ROUTE_NAMES = {
    bannerProject: "banner-project",
};

const BannerApp = () => import(/* webpackChunkName: "banner_app" */ "./BannerApp.vue");
const Projects = () => import(/* webpackChunkName: "banner_app" */ "./projects/Projects.vue");
const Settings = () => import(/* webpackChunkName: "banner_app" */ "./settings/Settings.vue");
const Panel = () => import(/* webpackChunkName: "banner_app" */ "./panel/Panel.vue");

export const bannerRoutes = {
    path: "/banner-old",
    component: BannerApp,
    children: [
        { path: "", component: Projects },
        { path: "projects", component: Projects },
        { path: "settings", component: Settings },
        {
            path: "projects/:project_uuid",
            name: BANNER_ROUTE_NAMES.bannerProject,
            component: Panel,
        },
    ],
};
