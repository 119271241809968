<template>
    <div class="fixed top-2 inset-x-3 mx-auto lg:w-1/2 z-50">
        <c-alert v-for="(alert, index) in alerts" :key="index" :alertData="alert" :index="index" />
    </div>
</template>
\

<script>
import { mapState } from "vuex";
import cAlert from "@/shared-old/components/cAlert.vue";
export default {
    name: "vAlerts",
    components: { cAlert },
    computed: {
        ...mapState(["alerts"]),
    },
};
</script>
