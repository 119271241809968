import axiosInstance from "@/shared-old/config/easyaxios";
import { localStorageService, STORAGE_KEY } from "@/shared/services/local-storage.service";
import { makeLogger } from "@/shared/consola";

const errors = {
    CODE_OR_CLIENT_ID_NOT_PROVIDED: "CODE_OR_CLIENT_ID_NOT_PROVIDED",
};

const logger = makeLogger("loginService");

export const loginService = {
    generateLogInLink(lang) {
        return generateAuthServerLink(lang);
    },
    generateRegisterLink(lang) {
        return generateAuthServerLink(lang, "/register");
    },
    generateLogOutLink(lang, noRedirect) {
        return generateAuthServerLink(lang, "/logout", noRedirect);
    },
    generateForgotPasswordLink(lang) {
        return generateAuthServerLink(lang, "/forgot");
    },
    async logInWithCodeAndClientId(code, clientId, redirectUrl) {
        if (!code || !clientId) {
            throw new Error(errors.CODE_OR_CLIENT_ID_NOT_PROVIDED);
        }
        // try {
        const { data } = await axiosInstance.post("/auth/by-easy-id", {
            code,
            clientId,
        });
        // Change in local storage:
        localStorageService.saveValue(STORAGE_KEY.userId, data.id);
        logger.debug("After login, redirect to:", redirectUrl);
        location.replace(redirectUrl);
        // } catch (e) {
        //     const { magicUrl } = await userService.forgeMagicLink(
        //         store.getters.activeAppDashboardLink
        //     );
        //     location.replace(magicUrl);
        // }
    },
    async logOutLocally() {
        localStorageService.removeValue(STORAGE_KEY.userId);
        try {
            await axiosInstance.get("/auth/logout");
        } catch (e) {
            console.warn(e);
        }
    },
    async logOut(lang = "pl", noRedirect = false) {
        await loginService.logOutLocally();
        window.location = loginService.generateLogOutLink(lang, noRedirect);
    },
};

function generateAuthServerLink(lang, subSegment = "", noRedirect = false) {
    const { pathname = "" } = window?.location || {};
    const computedPathName = pathname.replace("/", "");
    const redirectUrl = computedPathName ? `&redirect=${computedPathName}` : "";
    const computedLang = lang ? `&lang=${lang}` : "";
    const isNotStaging = !process.env.VUE_APP_STAGING_ENV;
    const callback =
        ["production", "development"].includes(process.env.NODE_ENV) && isNotStaging
            ? "easytools"
            : `${process.env.VUE_APP_ORIGIN}/auth/callback`;
    logger.debug("generateAuthServerLink with redirectUrl", redirectUrl);
    return `${process.env.VUE_APP_AUTH_SERVER_URL}${subSegment}?callback=${callback}${
        noRedirect ? "" : redirectUrl
    }${computedLang}`;
}
