export const LEGAL_ROUTE_NAMES = {
    legalProject: "legal-project",
};

const LegalApp = () => import(/* webpackChunkName: "legal_app" */ "./LegalApp.vue");
const LegalProjects = () =>
    import(/* webpackChunkName: "legal_app" */ "./projects/LegalProjects.vue");
const LegalSettings = () =>
    import(/* webpackChunkName: "legal_app" */ "./settings/LegalSettings.vue");
const LegalPanel = () => import(/* webpackChunkName: "legal_app" */ "./panel/LegalPanel.vue");

export const legalRoutes = {
    path: "/legal-old",
    component: LegalApp,
    children: [
        { path: "", component: LegalProjects },
        { path: "projects", component: LegalProjects },
        { path: "settings", component: LegalSettings },
        {
            path: "projects/:project_uuid",
            name: LEGAL_ROUTE_NAMES.legalProject,
            component: LegalPanel,
        },
    ],
};
