import { makeLogger } from "@/shared/consola";

const logger = makeLogger("legal.router");

import(/* webpackChunkName: "legal_app" */ "./legal.route.navigate.js");

const LegalApp = () => import(/* webpackChunkName: "legal_app" */ "./LegalApp.vue");
const LegalProjectPage = () =>
    import(/* webpackChunkName: "legal_app" */ "./pages/LegalProjectPage.vue");
const LegalListPage = () => import(/* webpackChunkName: "legal_app" */ "./pages/LegalListPage.vue");

// Sidebar menus:
const SidebarCompany = () =>
    import(/* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarCompany.vue");
const SidebarFooterNavigation = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarFooterNavigation.vue"
    );
const SidebarFooterContent = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarFooterContent.vue"
    );
const SidebarFooterSocials = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarFooterSocials.vue"
    );

const SidebarStyles = () =>
    import(/* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/SidebarStyles.vue");
const TermsSettings = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/pages/TermsSettings.vue"
    );
const PrivacySettings = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/pages/PrivacySettings.vue"
    );
const AboutSettings = () =>
    import(
        /* webpackChunkName: "legal_app" */ "@/apps/legal/project/sidebar/pages/AboutSettings.vue"
    );

export const LEGAL_ROUTE_NAMES = {
    projectPage: "project-legal-page",
    projectPageSidebarCompany: "project-page-sidebar-company",
    projectPageSidebarFooterNavigation: "project-page-sidebar-footer-navigation",
    projectPageSidebarFooterContent: "project-page-sidebar-footer-content",
    projectPageSidebarFooterSocials: "project-page-sidebar-footer-socials",
    projectPageSidebarStyles: "project-page-sidebar-styles",
    projectPageSidebarPagesTerms: "project-page-sidebar-pages-terms",
    projectPageSidebarPagesPolicy: "project-page-sidebar-pages-policy",
    projectPageSidebarPagesAbout: "project-page-sidebar-pages-about",
    listPage: "list-legal-page",
};

export const LEGAL_PREVIEW_KIND = {
    terms: "terms",
    privacy: "privacy",
    about: "about",
    footer: "footer",
};

export const LEGAL_FIRST_SEGMENT = "legal";

export const legalRoutes = [
    {
        path: `/${LEGAL_FIRST_SEGMENT}`,
        component: LegalApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: LEGAL_ROUTE_NAMES.projectPage,
                component: LegalProjectPage,
                children: [
                    {
                        path: "company",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarCompany,
                        component: SidebarCompany,
                    },
                    {
                        path: "footer-navigation",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarFooterNavigation,
                        component: SidebarFooterNavigation,
                        meta: { menuCategory: "footer" },
                    },
                    {
                        path: "footer-content",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarFooterContent,
                        component: SidebarFooterContent,
                        meta: { menuCategory: "footer" },
                    },
                    {
                        path: "footer-socials",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarFooterSocials,
                        component: SidebarFooterSocials,
                        meta: { menuCategory: "footer" },
                    },
                    {
                        path: "pages-terms",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarPagesTerms,
                        component: TermsSettings,
                    },
                    {
                        path: "pages-policy",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarPagesPolicy,
                        component: PrivacySettings,
                    },
                    {
                        path: "pages-about",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarPagesAbout,
                        component: AboutSettings,
                    },
                    {
                        path: "styles",
                        name: LEGAL_ROUTE_NAMES.projectPageSidebarStyles,
                        component: SidebarStyles,
                    },
                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                LEGAL_ROUTE_NAMES.projectPageSidebarCompany
                            );
                            return {
                                name: LEGAL_ROUTE_NAMES.projectPageSidebarCompany,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: LEGAL_ROUTE_NAMES.listPage,
                component: LegalListPage,
            },
        ],
    },
];
