export const PAGE_ROUTE_NAMES = {
    pageProject: "page-project",
};

const PageApp = () => import(/* webpackChunkName: "page_app" */ "./PageApp.vue");
const PagePanel = () => import(/* webpackChunkName: "page_app" */ "./panel/PagePanel.vue");
const PageProjects = () => import(/* webpackChunkName: "page_app" */ "./projects/PageProjects.vue");
const PageSettings = () => import(/* webpackChunkName: "page_app" */ "./settings/PageSettings.vue");

export const pageRoutes = {
    path: "/page-old",
    component: PageApp,
    children: [
        { path: "", component: PageProjects },
        { path: "settings", component: PageSettings },
        { path: "projects", component: PageProjects },
        {
            path: "projects/:project_uuid",
            name: PAGE_ROUTE_NAMES.pageProject,
            component: PagePanel,
        },
    ],
};
