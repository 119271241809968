<template>
    <transition
        enter-class="transform opacity-0"
        enter-active-class="transition ease-out duration-300"
        enter-from-class="transform opacity-0 "
        enter-to-class="transform opacity-100 "
        leave-active-class="transition ease-in duration-200"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
        appear
    >
        <div
            :class="[
                alertData.type === 'wrong'
                    ? 'bg-red-50'
                    : alertData.type === 'warning'
                      ? 'bg-yellow-50'
                      : 'bg-green-50',
            ]"
            class="mt-1 rounded-md p-4"
        >
            <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationTriangleIcon
                        v-if="alertData.type === 'warning'"
                        class="h-5 w-5 text-yellow-400"
                    />
                    <XCircleIcon v-if="alertData.type === 'wrong'" class="h-5 w-5 text-red-400" />
                    <CheckCircleIcon
                        v-if="alertData.type === 'success'"
                        class="h-5 w-5 text-green-400"
                    />
                </div>
                <div class="ml-3">
                    <h3
                        v-if="alertData.title"
                        :class="[
                            alertData.type == 'wrong'
                                ? 'text-red-800'
                                : alertData.type == 'warning'
                                  ? 'text-yellow-800'
                                  : 'text-green-800',
                        ]"
                        class="text-sm font-medium"
                    >
                        {{ alertData.title }}
                    </h3>
                    <div
                        v-if="alertData.text || alertData.points"
                        :class="[
                            alertData.type == 'wrong'
                                ? 'text-red-700'
                                : alertData.type == 'warning'
                                  ? 'text-yellow-700'
                                  : 'text-green-700',
                        ]"
                        class="mt-2 text-sm"
                    >
                        <p v-if="alertData.text">{{ alertData.text }}.</p>

                        <ul v-if="alertData.points" role="list" class="list-disc pl-5 space-y-1">
                            <li v-for="(point, index) in alertData.points" :key="index">
                                {{ point }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                        <button
                            @click="closeAlert(index)"
                            type="button"
                            :class="[
                                alertData.type == 'wrong'
                                    ? 'text-red-500 bg-red-50 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600'
                                    : alertData.type == 'warning'
                                      ? 'text-yellow-500 bg-yellow-50 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600'
                                      : 'text-green-500 bg-green-50 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
                            ]"
                            class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            <span class="sr-only">Dismiss</span>
                            <XMarkIcon class="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapMutations } from "vuex";
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    XCircleIcon,
    XMarkIcon,
} from "@heroicons/vue/20/solid";

export default {
    name: "cAlert",
    props: { alertData: Object, index: Number },

    components: {
        ExclamationTriangleIcon,
        CheckCircleIcon,
        XCircleIcon,
        XMarkIcon,
    },
    methods: {
        ...mapMutations(["closeAlert"]),
    },
};
</script>
